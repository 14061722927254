import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Row, Col, ListGroup, Offcanvas } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  modifyArticle,
  createFigure
} from './articleAPI'
import {
  ArticleFigureForm,
  ArticleAreaForm,
  ArticleHeadingsForm,
  ArticlePreambleForm,
  ArticleTemplateArticlesForm,
  TagEditor,
  normalHeadingTypes,
  getSubmitParamForArticleUpsert,
  isSizedArticleType,
  isPositionRequired,
  stripTags,
  getNumberOfRows,
  getParagraphStyleName,
  changeParagraphStyleMultiline
} from '../article'
import {
  CustomForm,
  FigureType
} from '../common'
import { shouldShowExperimentalFeatures } from '../auth'
import { selectCell } from './articleSlice'

const ArticleHeadingsDetail = ({
  article,
  layoutableType,
  layoutableId,
  layoutable,
  enumValues,
  handleChangeArticleSubmit,
  ...props
}) => {
  if (!article?.headings?.length) {
    return <>見出しなし</>
  }
  const basicNormalHeadings = article?.headings?.filter(h => normalHeadingTypes.has(h.headingType))
  const firstNormalheading = basicNormalHeadings?.[0]
  const { headingNumberOfOccupyingColumns, headingNumberOfOccupyingRows } = article
  const form = {
    initialValues: {
      article,
      layoutableType,
      layoutableId,
      layoutableLockVersion: layoutable?.lockVersion,
      headingNumberOfOccupyingColumns,
      headingNumberOfOccupyingRows,
      headingObjectStyleType: firstNormalheading?.headingObjectStyleType || '',
      keepOpen: true
    },
    handleSubmit: handleChangeArticleSubmit,
    validationSchema: ArticleHeadingsForm.schema,
    Component: ArticleHeadingsForm,
    props: {
      ...props,
      layoutableType,
      layoutableId,
      layoutable,
      article,
      enumValues
    }
  }
  return (
    <CustomForm form={form} />
  )
}

const ArticleFigure = ({
  article,
  layoutableType,
  layoutableId,
  figure,
  enumValues,
  ...props
}) => {
  const form = {
    initialValues: {
      ...figure,
      articleId: article.id
    },
    validationSchema: ArticleFigureForm.schema,
    Component: ArticleFigureForm,
    props: {
      ...props,
      ...{
        figureId: figure.id,
        articleId: article.id,
        layoutableType,
        layoutableId,
        enumValues,
        article
      }
    }
  }
  return <CustomForm form={form} />
}

const AddFigureForm = ({
  article,
  layoutableId,
  layoutableType,
  isSelectedLayoutableWritable
  // ...props
}) => {
  const emptyFigure = {
    articleId: article.id,
    figureType: FigureType.Photo,
    caption: ''
  }
  const dispatch = useDispatch()
  return (
    <Button
      icon='plus'
      variant='primary'
      size='sm'
      disabled={!isSelectedLayoutableWritable || article.layoutLocked}
      onClick={(e) => {
        e.preventDefault()
        dispatch(createFigure({ layoutableType, layoutableId, figure: emptyFigure }))
        dispatch(selectCell(null))
      }}
    >
      <FontAwesomeIcon icon='plus' fixedWidth />追加
    </Button>
  )
}

const ArticleFiguresDetail = ({
  article,
  ...props
}) => {
  if (!article?.figures?.length) {
    return (
      <>
        <Row><Col>写真・図なし</Col></Row>
        <AddFigureForm article={article} {...props} />
      </>
    )
  }
  return (
    <>
      <ListGroup>
        {article.figures.map(figure =>
          <ArticleFigure key={figure.id} article={article} figure={figure} {...props} />
        )}
      </ListGroup>
      <AddFigureForm article={article} {...props} />
    </>
  )
}

const ArticleArea = ({
  article,
  layoutableType,
  layoutableId,
  area,
  enumValues,
  ...props
}) => {
  const form = {
    initialValues: {
      ...area,
      isPositionRequired: isPositionRequired(article.articleType)
    },
    validationSchema: ArticleAreaForm.schema,
    Component: ArticleAreaForm,
    props: {
      ...props,
      ...{
        areaId: area.id,
        layoutableType,
        layoutableId,
        enumValues,
        article
      }
    }
  }
  return <CustomForm form={form} />
}

const ArticleAreasDetail = ({
  article,
  ...props
}) => {
  if (isSizedArticleType(article.articleType) || !article?.areas?.length) {
    return <>領域なし</>
  }
  return (
    <ListGroup>
      {article.areas.map(area =>
        <ArticleArea key={area.id} article={article} area={area} {...props} />
      )}
    </ListGroup>
  )
}

const detailPanePreStyle = {
  overflow: 'auto',
  wordWrap: 'normal',
  whiteSpace: 'pre-wrap'
}

const ArticleBodyDetail = ({
  article,
  layoutableType,
  layoutableId,
  layoutable,
  isSelectedLayoutableWritable
  // ...props
}) => {
  if (!article.bodyContent) {
    return <>本文なし</>
  }
  const dispatch = useDispatch()
  const paragraphStyleName = getParagraphStyleName(layoutable.setting, 'body')

  const onSubmit = (taggedContent) => {
    const bodyContent = stripTags(taggedContent)
    const numberOfCharactersPerRow = layoutable?.setting?.columnSetting?.numberOfCharactersPerRow
    const bodyNumberOfRows = !bodyContent ? null : getNumberOfRows(numberOfCharactersPerRow, taggedContent, true)
    const bodyTaggedContent = changeParagraphStyleMultiline(taggedContent, paragraphStyleName)
    const newArticle = { ...article, bodyContent, bodyTaggedContent, bodyNumberOfRows }
    const props = { layoutableType, layoutableId, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
    // console.log(newArticle)
    const submitParam = getSubmitParamForArticleUpsert(props)
    dispatch(modifyArticle(submitParam))
    dispatch(selectCell(null))
  }
  const inputDisabled = !isSelectedLayoutableWritable || article.layoutLocked
  const editable = !inputDisabled && shouldShowExperimentalFeatures()
  return (
    <>
      <Row className='mb-2'>
        <Col>
          <div className='bodyNumberOfRows fs-5'>本文行数: {article.bodyNumberOfRows}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TagEditor
            initialTaggedContent={article.bodyTaggedContent}
            styleTagType='sentence'
            setting={layoutable.setting}
            onSubmit={onSubmit}
            disabled={inputDisabled}
            editable={editable}
            allowLineBreak
            allowMultiParagraph
          />
        </Col>
      </Row>
    </>
  )
}

const ArticlePreambleDetail = ({
  layoutableType,
  layoutableId,
  layoutable,
  article,
  handleChangeArticleSubmit,
  ...props
}) => {
  const { preambleNumberOfColumns, preambleNumberOfRows, preambleNumberOfCharactersPerRow, preambleMarginRows } = article
  const form = {
    initialValues: {
      layoutableType,
      layoutableId,
      layoutableLockVersion: layoutable?.lockVersion,
      article,
      preambleNumberOfColumns,
      preambleNumberOfRows,
      preambleNumberOfCharactersPerRow,
      preambleMarginRows
    },
    handleSubmit: handleChangeArticleSubmit,
    validationSchema: ArticlePreambleForm.schema,
    Component: ArticlePreambleForm,
    props: {
      ...props,
      layoutableType,
      layoutableId,
      layoutable,
      detailPanePreStyle
    }
  }
  return (
    <CustomForm form={form} />
  )
}

const ArticleTemplateArticlesDetail = ({
  article,
  layoutableType,
  layoutableId,
  ...props
}) => {
  const form = {
    initialValues: {
      layoutableType,
      layoutableId,
      article
    },
    // handleSubmit: handleChangeArticleSubmit,
    validationSchema: ArticleTemplateArticlesForm.schema,
    Component: ArticleTemplateArticlesForm,
    props: {
      ...props,
      article,
      layoutableType,
      layoutableId
    }
  }
  return (
    <CustomForm form={form} />
  )
}

const ArticleDetailBody = ({
  articles,
  cell,
  ...props
}) => {
  if (!articles || !cell) {
    return (<>Click Cell to see details</>)
  }
  const article = articles?.at(cell.rowId)
  switch (cell.column) {
    case 'headings':
      return (<ArticleHeadingsDetail {...props} {...{ article }} />)
    case 'figures':
      return (<ArticleFiguresDetail {...props} {...{ article }} />)
    case 'areas':
      return (<ArticleAreasDetail {...props} {...{ article }} />)
    case 'bodyColumn':
      return (<ArticleBodyDetail {...props} {...{ article }} />)
    case 'preambleColumn':
      return (<ArticlePreambleDetail {...props} {...{ article }} />)
    case 'templateArticle':
      return (<ArticleTemplateArticlesDetail {...props} {...{ article }} />)
    default:
      return (<>unknown</>)
  }
}

const getDetailTitle = (column) => {
  switch (column) {
    case 'headings':
      return '見出し'
    case 'figures':
      return '写真・図'
    case 'areas':
      return '領域'
    case 'bodyColumn':
      return '本文'
    case 'preambleColumn':
      return '前文'
    case 'templateArticle':
      return '在版'
    default:
      return null
  }
}

export const ArticleDetail = ({
  cell,
  unselectCell,
  ...props
}) => {
  if (!cell) return
  const detailTitle = getDetailTitle(cell.column)
  if (!detailTitle) return
  return (
    <Offcanvas show={cell !== null} onHide={unselectCell} placement='end' backdrop='static' style={{ width: '600px' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{detailTitle}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ArticleDetailBody cell={cell} {...props} {...{ unselectCell }} />
      </Offcanvas.Body>
    </Offcanvas>
  )
}
